<template>
  <vuestic-modal
    v-bind:large="true"
    ref="modal"
  >

    <div slot="title">
      Aplicar a Checklist
      <b>{{ nomeChecklist }}</b>
    </div>

    <div slot="footer">
      <button
        type="button"
        class="btn-micro btn btn-pale"
        @click="$refs.modal.cancel()"
      >
        VOLTAR
      </button>
      <button
        type="button"
        class="btn-micro btn btn-primary"
        @click="save"
      >
        CONFIRMAR
      </button>
    </div>

    <div class="convainer">
      <div class="row mb-5">
        <el-date-picker
          class="m-auto"
          format="dd/MM/yyyy"
          v-model="data"
          placeholder="Selecione uma data"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </div>
      <div class="row">
        <el-transfer
          class="m-auto"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="Funcionários"
          v-model="formAplicar.responsavel_resposta"
          :data="funcionarios"
          :props="props"
          :titles="['Funcionários', 'Funcionários Selecionados']"
        />
      </div>
    </div>
  </vuestic-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    nomeChecklist: {
      type: String,
      required: true,
    },
    responsavelId: {
      type: Number,
      required: true,
    },
    funcionarios: {
      type: Array,
      required: true,
    },
    acesso: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      formAplicar: {
        data_cadastro: '',
        data_finalização: '',
        responsavel_cadastro: null,
        responsavel_resposta: [],
        acesso: null,
      },
      props: {
        label: 'nome',
        key: 'id',
      },
    };
  },
  created() {
    this.formAplicar.responsavel_cadastro = this.responsavelId;
    this.formAplicar.acesso = this.acesso;
  },
  methods: {
    ...mapActions('aplicar', ['postAplicar']),
    open() {
      this.data = null;
      this.formAplicar.data_cadastro = '';
      this.formAplicar.data_finalização = '';
      this.formAplicar.responsavel_resposta = [];
      this.$refs.modal.open();
    },
    filterMethod(query, item) {
      return item.nome.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    async save() {
      try {
        if (!this.validateForm()) {
          this.$swal('Atenção!',
            'Verifique se todos os campos estão preenchidos!',
            'info');
          return;
        }
        let res = {};
        res = await this.postAplicar(this.formAplicar);
        this.$refs.modal.ok();
        this.$emit('save', res);
      } catch (error) {
        console.error(error);
        this.$emit('error', error);
      }
    },
    validateForm() {
      return this.formAplicar.responsavel_resposta.length > 0 && this.data !== null;
    },
  },
  watch: {
    responsavelId(val) {
      this.formAplicar.responsavel_cadastro = val;
    },
    acesso(val) {
      this.formAplicar.acesso = val;
    },
    data(data) {
      this.formAplicar.data_cadastro = data;
      this.formAplicar.data_finalização = data;
    },
  },
  computed: {
    okDisabled() {
      return false;
    },
  },
};
</script>

<style>
.el-transfer-panel {
	width: 300px !important;
}
</style>
